import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 8,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h2 className="yellowtxt">What Parents Say</h2>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                        <div className="item carousel-item active">
                            <p className="testimonial">
                            This our most favourite place! COVID MEASURES- Excellent. Professionally cleaned. Strictly by government guidelines. My toddler loves going there! Many sensory play options, pretend and play, a calming room for taking a power nap, and much more.
                            </p>
                            <p className="overview">- Kalyani P.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            My kids absolutely love it here! It provides hours of fun for the kids. We can’t wait to keep going back. The owner is very pleasant and welcoming. It’s extremely well run and clean!
</p>
                            <p className="overview">- Jill Q.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            Took the kids there this evening for a surprise playtime. We booked the facility for a 2 hour private playtime and were seriously impressed. Very clean and organized and the staff are so kind and thoughtful. There are all kinds of really cool indoor swings and a zip line that the kids can’t get enough of.
</p>
                            <p className="overview">
                            - Ela S.</p>
                        </div>

                         <div className="item carousel-item">
                            <p className="testimonial">
                            Great place to get out and play. Wasn’t chaotic and noisy like similar indoor play parks. Very pleasant staff, warm and welcoming. Best value is to purchase ten visit pass. Monthly pass available to.
</p>
                            <p className="overview">
                            - Crystal M. </p>
                        </div> 
                        <div className="item carousel-item">
                            <p className="testimonial">
                            I am a TA from Brampton. We went here on a field trip with our students with special needs. We loved it and so did the kids! Staff were very friendly and welcoming. Lots to do for the kids. Even a calming room for the kids with autism. Lots of swings which they love. Clean and well organized facility.
</p>
                            <p className="overview">
                            - Janine </p>
                        </div> 
                        <div className="item carousel-item">
                            <p className="testimonial">
                            Such a great space. Friendly employees and owner. So much fun here. It’s clean and spacious. We will definitely return to play again.
</p>
                            <p className="overview">
                            - Samantha L.</p>
                        </div> 
                        <div className="item carousel-item">
                            <p className="testimonial">
                            Almost like our second home. Finally a pace my son can be himself.. my son also take aba music classes. Love the themed parties .
</p>
                            <p className="overview">
                            - M.K. </p>
                        </div> 
                        <div className="item carousel-item">
                            <p className="testimonial">
                            Great Sensory Gym for children of all abilities. They have a mini zipline, play structure, multiple different therapy swings, a rock climbing/monkey bars station, a pretend play area, table activities including colouring and board games, a party room, and a snoezelen room. We went here for a field trip, and the children had such a great time! The staff were so friendly and helpful.
</p>
                            <p className="overview">
                            - Maddie M. </p>
                        </div> 
                       
                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
